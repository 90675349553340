export default {
  en: {
    pattern: 'This field is invalid',
    invalid: 'This field is invalid',
    required: 'The field value is required',
    number: 'The field value is not a number',
    integer: 'The field value is not an integer number',
    url: 'The field value is a invalid url',
    email: 'The field value is not a email format',
    ipv6: 'The field value is not a ipv6 format',
    ipv4: 'The field value is not a ipv4 format',
    idcard: 'The field value is not an idcard format',
    qq: 'The field value is not a qq number format',
    phone: 'The field value is not a phone number format',
    money: 'The field value is not a currency format',
    zh: 'The field value is not a chinese string',
    date: 'The field value is not a valid date format',
    zip: 'The field value is not a zip format',
    len: 'The length or number of entries must be {{len}}',
    min: 'The length or number of entries must be at least {{min}}',
    minLength: 'The length or number of entries must be at least {{minLength}}',
    minItems: 'The length or number of entries must be at least {{minItems}}',
    maximum: 'The field value cannot be greater than {{maximum}}',
    exclusiveMaximum: 'The field value must be less than {{exclusiveMaximum}}',
    minimum: 'The field value cannot be less than {{minimum}}',
    exclusiveMinimum:
      'The field value must be greater than {{exclusiveMinimum}}',
    max: 'The field length or number of entries must be at most {{max}}',
    maxLength:
      'The field length or number of entries must be at most {{maxLength}}',
    maxItems:
      'The field length or number of entries must be at most {{maxItems}}',
    whitespace: 'This field value cannot be blank string.',
    enum: 'The field value must be one of {{enum}}',

    const: 'The field value must be equal to {{const}}',
    multipleOf: 'The field value must be divisible by {{multipleOf}}',
    maxProperties:
      'The number of field properties cannot be greater than {{maxProperties}}',
    minProperties:
      'The number of field properties cannot be less than {{maxProperties}}',
    uniqueItems: 'Array elements are not unique',
  },
  zh: {
    pattern: '该字段不是一个合法的字段',
    invalid: '该字段不是一个合法的字段',
    required: '该字段是必填字段',
    number: '该字段不是合法的数字',
    integer: '该字段不是合法的整型数字',
    url: '该字段不是合法的url',
    email: '该字段不是合法的邮箱格式',
    ipv6: '该字段不是合法的ipv6格式',
    ipv4: '该字段不是合法的ipv4格式',
    idcard: '该字段不是合法的身份证格式',
    qq: '该字段不符合QQ号格式',
    phone: '该字段不是有效的手机号',
    money: '该字段不是有效货币格式',
    zh: '该字段不是合法的中文字符串',
    date: '该字段不是合法的日期格式',
    zip: '该字段不是合法的邮编格式',
    len: '长度或条目数必须为{{len}}',
    min: '长度或条目数不能小于{{min}}',
    minLength: '长度或条目数不能小于{{minLength}}',
    minItems: '长度或条目数不能小于{{minItems}}',
    max: '长度或条目数不能大于{{max}}',
    maxLength: '长度或条目数不能大于{{maxLength}}',
    maxItems: '长度或条目数不能大于{{maxItems}}',
    maximum: '数值不能大于{{maximum}}',
    exclusiveMaximum: '数值必须小于{{exclusiveMaximum}}',
    minimum: '数值不能小于{{minimum}}',
    exclusiveMinimum: '数值必须大于{{exclusiveMinimum}}',
    whitespace: '不能为纯空白字符串',
    enum: '字段值必须为{{enum}}其中一个',
    const: '字段值必须等于{{const}}',
    multipleOf: '字段值不能被{{multipleOf}}整除',
    maxProperties: '字段属性数量不能大于{{maxProperties}}',
    minProperties: '字段属性数量不能小于{{minProperties}}',
    uniqueItems: '数组元素不唯一',
  },
  'en-US': {
    pattern: 'This field is invalid',
    invalid: 'This field is invalid',
    required: 'The field value is required',
    number: 'The field value is not a number',
    integer: 'The field value is not an integer number',
    url: 'The field value is a invalid url',
    email: 'The field value is not a email format',
    ipv6: 'The field value is not a ipv6 format',
    ipv4: 'The field value is not a ipv4 format',
    idcard: 'The field value is not an idcard format',
    qq: 'The field value is not a qq number format',
    phone: 'The field value is not a phone number format',
    money: 'The field value is not a currency format',
    zh: 'The field value is not a chinese string',
    date: 'The field value is not a valid date format',
    zip: 'The field value is not a zip format',
    len: 'The length or number of entries must be {{len}}',
    min: 'The length or number of entries must be at least {{min}}',
    minLength: 'The length or number of entries must be at least {{minLength}}',
    minItems: 'The length or number of entries must be at least {{minItems}}',
    maximum: 'The field value cannot be greater than {{maximum}}',
    exclusiveMaximum: 'The field value must be less than {{exclusiveMaximum}}',
    minimum: 'The field value cannot be less than {{minimum}}',
    exclusiveMinimum:
      'The field value must be greater than {{exclusiveMinimum}}',
    max: 'The field length or number of entries must be at most {{max}}',
    maxLength:
      'The field length or number of entries must be at most {{maxLength}}',
    maxItems:
      'The field length or number of entries must be at most {{maxItems}}',
    whitespace: 'This field value cannot be blank string.',
    enum: 'The field value must be one of {{enum}}',
    const: 'The field value must be equal to {{const}}',
    multipleOf: 'The field value must be divisible by {{multipleOf}}',
    maxProperties:
      'The number of field properties cannot be greater than {{maxProperties}}',
    minProperties:
      'The number of field properties cannot be less than {{maxProperties}}',
    uniqueItems: 'Array elements are not unique',
  },
  'zh-CN': {
    pattern: '该字段不是一个合法的字段',
    invalid: '该字段不是一个合法的字段',
    required: '该字段是必填字段',
    number: '该字段不是合法的数字',
    integer: '该字段不是合法的整型数字',
    url: '该字段不是合法的url',
    email: '该字段不是合法的邮箱格式',
    ipv6: '该字段不是合法的ipv6格式',
    ipv4: '该字段不是合法的ipv4格式',
    idcard: '该字段不是合法的身份证格式',
    qq: '该字段不符合QQ号格式',
    phone: '该字段不是有效的手机号',
    money: '该字段不是有效货币格式',
    zh: '该字段不是合法的中文字符串',
    date: '该字段不是合法的日期格式',
    zip: '该字段不是合法的邮编格式',
    len: '长度或条目数必须为{{len}}',
    min: '长度或条目数不能小于{{min}}',
    minLength: '长度或条目数不能小于{{minLength}}',
    minItems: '长度或条目数不能小于{{minItems}}',
    maxLength: '长度或条目数不能大于{{maxLength}}',
    maxItems: '长度或条目数不能大于{{maxItems}}',
    max: '长度或条目数不能大于{{max}}',
    maximum: '数值不能大于{{maximum}}',
    exclusiveMaximum: '数值必须小于{{exclusiveMaximum}}',
    minimum: '数值不能小于{{minimum}}',
    exclusiveMinimum: '数值必须大于{{exclusiveMinimum}}',
    whitespace: '不能为纯空白字符串',
    enum: '字段值必须为{{enum}}其中一个',
    const: '字段值必须等于{{const}}',
    multipleOf: '字段值不能被{{multipleOf}}整除',
    maxProperties: '字段属性数量不能大于{{maxProperties}}',
    minProperties: '字段属性数量不能小于{{minProperties}}',
    uniqueItems: '数组元素不唯一',
  },
  'zh-TW': {
    pattern: '該字段不是一個合法的字段',
    invalid: '該字段不是一個合法的字段',
    required: '該字段是必填字段',
    number: '該字段不是合法的數字',
    integer: '該字段不是合法的整型數字',
    url: '該字段不是合法的url',
    email: '該字段不是合法的郵箱格式',
    ipv6: '該字段不是合法的ipv6格式',
    ipv4: '該字段不是合法的ipv4格式',
    idcard: '該字段不是合法的身份證格式',
    qq: '該字段不符合QQ號格式',
    phone: '該字段不是有效的手機號',
    money: '該字段不是有效貨幣格式',
    zh: '該字段不是合法的中文字符串',
    date: '該字段不是合法的日期格式',
    zip: '該字段不是合法的郵編格式',
    len: '長度或條目數必須為{{len}}',
    min: '長度或條目數不能小於{{min}}',
    minItems: '長度或條目數不能小於{{minItems}}',
    minLength: '長度或條目數不能小於{{minLength}}',
    max: '長度或條目數不能大於{{max}}',
    maxItems: '長度或條目數不能大於{{maxItems}}',
    maxLength: '長度或條目數不能大於{{maxLength}}',
    maximum: '數值不能大於{{maximum}}',
    exclusiveMaximum: '數值必須小於{{exclusiveMaximum}}',
    minimum: '數值不能小於{{minimum}}',
    exclusiveMinimum: '數值必須大於{{exclusiveMinimum}}',
    whitespace: '不能為純空白字符串',
    enum: '字段值必須為{{enum}}其中一個',
    const: '字段值必須等於{{const}}',
    multipleOf: '字段值不能被{{multipleOf}}整除',
    maxProperties: '字段屬性數量不能大於{{maxProperties}}',
    minProperties: '字段屬性數量不能小於{{minProperties}}',
    uniqueItems: '數組元素不唯一',
  },
  ja: {
    url: 'このフィールドは無効なURLです',
    whitespace: 'このフィールドを空の文字列にすることはできません。',
    zh: 'このフィールドは中国語の文字列ではありません',
    zip: 'このフィールドはzip形式ではありません',
    date: 'このフィールドは有効な日付形式ではありません',
    email: 'このフィールドはメール形式ではありません',
    exclusiveMaximum: '値は{{exclusiveMaximum}}未満である必要があります',
    exclusiveMinimum: '値は{{exclusiveMinimum}}より大きい必要があります',
    idcard: 'このフィールドはIDカード形式ではありません',
    integer: 'このフィールドは整数ではありません',
    ipv4: 'このフィールドはIPv4形式ではありません',
    ipv6: 'このフィールドはIPv6形式ではありません',
    len: 'エントリの長さまたは数は{{len}}でなければなりません',
    max: 'エントリの長さまたは数は最大{{max}}でなければなりません',
    maxItems: 'エントリの長さまたは数は最大{{maxItems}}でなければなりません',
    maxLength: 'エントリの長さまたは数は最大{{maxLength}}でなければなりません',
    maximum: '値は{{最大}}を超えることはできません',
    min: 'エントリの長さまたは数は、少なくとも{{min}}である必要があります',
    minItems:
      'エントリの長さまたは数は、少なくとも{{minItems}}である必要があります',
    minLength:
      'エントリの長さまたは数は、少なくとも{{minLength}}である必要があります',
    minimum: '値は{{minimum}}以上にする必要があります',
    money: 'このフィールドは通貨形式ではありません',
    number: 'このフィールドは数値ではありません',
    pattern: 'このフィールドはどのパターンとも一致しません',
    invalid: 'このフィールドはどのパターンとも一致しません',
    phone: 'このフィールドは電話番号の形式ではありません',
    qq: 'このフィールドはqq数値形式ではありません',
    required: 'この項目は必須です',
    enum: 'フィールド値は{{enum}}のいずれかである必要があります',
    cons: 'フィールド値は{{const}}と等しくなければなりません',
    multipleOf: 'フィールド値を{{multipleOf}}で割り切れない',
    maxProperties:
      'フィールドプロパティの数は{{maxProperties}}を超えることはできません',
    minProperties:
      'フィールドプロパティの数は{{minProperties}}未満にすることはできません',
    uniqueItems: '配列要素は一意ではありません',
  },
}
